<template>
  <div class="carrier-plan-selector">
    <div class="option-list" v-for="(option, index) in options" :key="index">
      <div
        class="option-selector"
        :class="{
          active: plan === option.value,
          disabled: loadingCargoValue || disableButtons,
        }"
        @click="selectedPlan(option.value)"
        v-loading="loadingCargoValue"
      >
        <div class="image-container" v-html="option.iconImage"></div>
        <div class="option-information">
          <h5 class="option-information__title">{{ option.title }}</h5>
          <span class="option-information__text">{{ option.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarrierPlanSelector",
  props: {
    loadingCargoValue: Boolean,
    disableButtons: Boolean,
    cargoValue: Number,
  },
  data() {
    return {
      plan: "basic",
      options: [
        {
          iconImage: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none"
          xmlns="http://www.w3.org/2000/svg">
  <path
    d="M28 0C28.4983 0 28.9967 0.109976 29.4517 0.318932L49.8508 9.10605C52.2341 10.1288 54.0108 12.5153 53.9999 15.3967C53.9458 26.3064 49.5258 46.2671 30.86 55.3401C29.0508 56.22 26.9492 56.22 25.14 55.3401C6.47421 46.2671 2.05422 26.3064 2.00005 15.3967C1.98922 12.5153 3.76588 10.1288 6.14921 9.10605L26.5592 0.318932C27.0033 0.109976 27.5017 0 28 0Z"
    fill="#043663" />
  <g clip-path="url(#clip0_63_1366)">
    <path
      d="M32.2724 35.317L20.944 23.8167C19.9961 25.1614 19.4417 26.811 19.4417 28.5938C19.4417 33.1475 23.081 36.8421 27.5667 36.8421C29.3229 36.8421 30.9479 36.2793 32.2724 35.317ZM34.1894 33.3709C35.1373 32.0263 35.6917 30.3767 35.6917 28.5938C35.6917 24.0401 32.0524 20.3456 27.5667 20.3456C25.8105 20.3456 24.1855 20.9084 22.861 21.8707L34.1894 33.3709ZM16.7334 28.5938C16.7334 25.6771 17.8748 22.8798 19.9064 20.8173C21.938 18.7549 24.6935 17.5962 27.5667 17.5962C30.4399 17.5962 33.1954 18.7549 35.227 20.8173C37.2587 22.8798 38.4 25.6771 38.4 28.5938C38.4 31.5106 37.2587 34.3079 35.227 36.3703C33.1954 38.4328 30.4399 39.5915 27.5667 39.5915C24.6935 39.5915 21.938 38.4328 19.9064 36.3703C17.8748 34.3079 16.7334 31.5106 16.7334 28.5938Z"
      fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_63_1366">
      <rect width="21.6666" height="21.9953" fill="white" transform="translate(16.7334 17.5962)" />
    </clipPath>
  </defs>
</svg>`,
          value: "basic",
          title: "No extra coverage",
          text: "Up to $25,000 of declared value only for $35",
        },
        {
          iconImage: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none"
          xmlns="http://www.w3.org/2000/svg">
  <path
    d="M28 0C28.4983 0 28.9967 0.109976 29.4517 0.318932L49.8508 9.10605C52.2341 10.1288 54.0108 12.5153 53.9999 15.3967C53.9458 26.3064 49.5258 46.2671 30.86 55.3401C29.0508 56.22 26.9492 56.22 25.14 55.3401C6.47421 46.2671 2.05422 26.3064 2.00005 15.3967C1.98922 12.5153 3.76588 10.1288 6.14921 9.10605L26.5592 0.318932C27.0033 0.109976 27.5017 0 28 0Z"
    fill="#043663" />
  <g clip-path="url(#clip0_64_1373)">
    <path
      d="M39.4755 21.1465C40.173 21.7568 40.173 22.748 39.4755 23.3584L25.1898 35.8584C24.4922 36.4688 23.3594 36.4688 22.6619 35.8584L15.519 29.6084C14.8215 28.998 14.8215 28.0068 15.519 27.3965C16.2166 26.7861 17.3494 26.7861 18.0469 27.3965L23.9286 32.5381L36.9532 21.1465C37.6507 20.5361 38.7835 20.5361 39.4811 21.1465H39.4755Z"
      fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_64_1373">
      <rect width="25" height="25" fill="white" transform="translate(15 16)" />
    </clipPath>
  </defs>
</svg>`,
          value: "insurance",
          title: "Quote full coverage",
          text: "Enter cargo value to calculate your new insurance price",
        },
      ],
    };
  },
  created() {
    if (this.cargoValue > 0) {
      this.plan = "insurance";
      this.selectedPlan(this.plan);
    }
  },
  methods: {
    selectedPlan(plan) {
      if (this.loadingCargoValue || this.disableButtons) return;
      this.plan = plan;
      this.$emit("handleSelectedPlan", this.plan);
    },
  },
};
</script>

<style lang="scss" scoped>
.carrier-plan-selector {
  display: flex;
  gap: 10px;
}
.option-list {
  width: 50%;
}
.option-selector {
  display: flex;
  padding: 5px;
  margin: 15px auto;
  align-items: center;
  position: relative;
  min-width: 195px;
  width: 100%;
  height: 75%;
  max-height: 122px;
  border: 1px solid rgba(128, 128, 128, 0.242);
  border-radius: 8px;
  cursor: pointer;
  input {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
::v-deep .image-container svg {
  width: 55px;
  g {
    fill: $color-primary-company;
  }
}
.active {
  // Ask sebas. background: lighten($color-border-container, 20%);
  background: #a5dae024;
  border: 2px solid $color-border-container;
  input[type="radio"] {
    accent-color: $color-border-container;
  }
}
.option-information {
  color: $color-primary-company;
  line-height: 15px;
  padding: 5px;
  &__title {
    font-weight: bold;
    font-size: 15px;
    margin: 0px;
  }
  &__text {
    font-weight: normal;
    font-size: 12px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

::v-deep {
  .el-loading-mask {
    border-radius: 8px;
  }
}

@media (max-width: 1203px) {
  .carrier-plan-selector {
    flex-direction: column;
  }
  .option-list {
    width: 100%;
  }
  .option-selector {
    margin: 2px auto;
  }
}
</style>
