import { mapGetters } from "vuex";
import dayjs from "dayjs";
import formatterCarrierName from "@/Extend/FormatCarrierName";
// eslint-disable-next-line max-len
import QuoteAcceptanceModal from "../views/QuoteBook/CarrierSelection/carriersList/components/QuoteAcceptanceModal.vue";
import FormatEasyPostPrices from "./FormatEasyPostPrices";

export default {
  props: {
    carrier: Object,
    carrierImage: Object,
    aditionalInsurance: Number,
    loadingInsurance: Boolean,
    originalCarrierArray: Array,
    isPickup: Boolean,
  },
  mixins: [formatterCarrierName, FormatEasyPostPrices],
  components: {
    QuoteAcceptanceModal,
  },
  data() {
    return {
      showModal: false,
      insurance: false,
      canDispatch: null,
      showPickupPrice: false,
    };
  },
  created() {
    this.canDispatch = this.$store.getters["login/getTokenInfo"].can_dispatch;
  },
  computed: {
    setTransitTimeTruck() {
      if (this.carrier.transitTimeHours) {
        return `${this.carrier.transitTimeHours} hours`;
      }
      return `${this.carrier.transitTime} days`;
    },
    setTransitTimeCarrier() {
      if (this.carrier.transitTime === 0 || this.carrier.carrier === "305 EXPRESS USA (LTL)" || this.carrier.carrier === "305 EXPRESS USA (FTL)") {
        return "Same day delivery";
      }
      return `${this.carrier.transitTime} Business days`;
    },
    ...mapGetters({
      carriersImages: "carriers/getCarriersImages",
    }),
    checkIfCarrierIsGuaranteed() {
      const serviceClass = this.carrier.serviceClass.toLowerCase();
      const serviceClassList = ["guaranteed", "expedited", "time critical", "accelerated"];
      return serviceClassList.some((item) => serviceClass.includes(item));
    },
  },
  methods: {
    findTruckImage(truck) {
      const carrierImage = this.carriersImages.find((image) => {
        const imageName = image.name
          .slice(image.name.indexOf("/") + 1, -4)
          .toLowerCase()
          .trim();
        return imageName === truck.carrier.toLowerCase();
      });
      return carrierImage?.url || "";
    },
    findCarrierImage(name) {
      const newImage = this.carriersImages.find(
        (image) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          image.name.toLowerCase().includes(name.toLowerCase())
      );
      if (newImage) {
        return newImage;
      }
      return { url: "" };
    },
    validateTruck(carrier) {
      const trucksSourcesList = ["otr", "athenea", "partial", "flatbed", "reefer"];
      return trucksSourcesList.includes(carrier.source.toLowerCase());
    },
    validateIfTheCarrierCanBuyPickup(carrier) {
      if (carrier.source === "easy-post" && (carrier.scac === "UPSN" || carrier.scac === "FDEG")) {
        this.showPickupPrice = true;
        return;
      }
      this.showPickupPrice = false;
    },
    addCarrierModeToName(carrier) {
      const carrierName = carrier.toLowerCase();
      if (carrierName.includes("vltl")) {
        return " (VLTL)";
      }
      if (carrierName.includes("ltl")) {
        return " (LTL)";
      }
      if (carrierName.includes("ftl")) {
        return " (FTL)";
      }
      return "";
    },
    selectedCarrier(insurance, carrier, pickup) {
      this.$emit("handleIsPickup", pickup);
      if (this.canDispatch || this.canDispatch === null) {
        if (carrier.is_expired) {
          this.swal({
            title: "This carrier is no longer available.",
            icon: "warning",
            dangerMode: false,
          });
        } else {
          this.validateIfTheCarrierCanBuyPickup(carrier);
          this.selectedCarrierOrTruck(insurance);
        }
      }
    },
    selectedCarrierOrTruck(insurance) {
      this.insurance = insurance;
      this.showModal = true;
      const { description, insuranceAlert } = this.carrier;
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "selectedTransport",
        value: this.carrier,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "portalNotes",
        value: description,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "insuranceAlert",
        value: insuranceAlert,
      });
    },
    optionSelectedCarrier({ enableLoyaltyInsurance, buyPickup, insurance }) {
      // eslint-disable-next-line max-len
      const { selectedTransport } = this.$store.getters["transportSelected/getTransportModalInformation"];
      const { _id } = selectedTransport;
      const { name } = selectedTransport;
      const params = {
        isInsurance: insurance,
        carrierId: _id,
        carrierName: name,
        loyaltyInsurance: enableLoyaltyInsurance,
        pickup: buyPickup
      };
      if (buyPickup) {
        const carrierWithPickup = this.originalCarrierArray.filter(
          (item) => item.carrier === selectedTransport.carrier && item.accesorials.some((accesorial) => accesorial.Name === "parcel pick up fee")
        );
        if (carrierWithPickup.length) {
          // eslint-disable-next-line no-underscore-dangle
          params.carrierId = carrierWithPickup[0]._id;
          params.carrierName = carrierWithPickup[0].carrier;
        }
      }
      this.$emit("optionSelectedCarrier", { ...params, changeView: true });
      this.closeModal();
    },
    optionSelectedTruck({ enableLoyaltyInsurance, insurance }) {
      const params = {
        isInsurance: insurance,
        truckName: this.carrier,
        loyaltyInsurance: enableLoyaltyInsurance
      };
      this.closeModal();
      this.$emit("optionSelectedTruck", params);
    },
    closeModal() {
      this.showModal = false;
    },
    dateExpired(expirationDate) {
      const today = new Date();
      return dayjs(today).isAfter(expirationDate);
    },
    checkIfCarrierHasExpired(carrier) {
      if ("is_expired" in carrier) {
        return carrier.is_expired;
      }
      return this.dateExpired();
    },
  },
};
