<template>
  <div class="carriers-list">
    <CarriersSorter
      :carriers="carriers"
      @getFilteredCarriers="getFilteredCarriers"
    ></CarriersSorter>
    <span class="divider"></span>
    <TrucksListSkeleton v-if="activeSpinner" />
    <div v-if="!activeSpinner">
      <div class="carriers-list__content">
        <CarrierMessage v-if="carriers.length !== 0 && !isBranded" />
        <div class="carriers-list__title">
          <p class="carriers-list__type">All Options</p>
          <p class="carriers-list__text-info">
            <!--Preguntar-->
            <small
              >Forget about modes or types of service. Compare all our options based on price or
              transit time, no matter if it is an expedited service, dedicated truck, LTL, or
              parcel, find the option that suits you best.</small
            >
          </p>
          <p v-if="rateCreationDate" class="carriers-list__type">
            <!--Preguntar-->
            <small>Last rate creation date: {{ rateCreationDate }}</small>
          </p>
        </div>
      </div>
      <div class="carriers-container" id="carriers-container" v-if="screenWidth > 800">
        <template v-if="chosenLoad">
          <Options
            v-for="(carrier, index) in filteredCarriers"
            :key="index"
            :carrier="carrier"
            :carrierImage="findCarrierImage(carrier)"
            :aditionalInsurance="aditionalInsurance"
            :loadingInsurance="loadingInsurance"
            :lineItems="chosenLoad.line_items"
            :originalCarrierArray="originalCarrierArray"
            :isPickup="isPickup"
            @handleIsPickup="handleIsPickup"
            @optionSelectedCarrier="optionSelectedCarrier"
            @optionSelectedTruck="optionSelectedTruck"
            class="mb-3"
          ></Options>
        </template>
        <template v-if="carriers.length === 0 && !allNotificationsObtained">
          <p>We are working to obtain carriers and trucks. This may take some time.</p>
          <b-spinner small label="Spinning"></b-spinner>
        </template>
        <div class="not-carriers">
          <p v-if="carriers.length === 0 && allNotificationsObtained">
            {{ $t("carriers.noCarriersFound") }}
          </p>
        </div>
      </div>
      <div class="carriers-container" id="carriers-container" v-if="screenWidth <= 800">
        <template v-if="chosenLoad">
          <OptionsResponsive
            v-for="(carrier, index) in filteredCarriers"
            :key="index"
            :idNumber="index"
            :carrier="carrier"
            :carrierImage="findCarrierImage(carrier)"
            :aditionalInsurance="aditionalInsurance"
            :loadingInsurance="loadingInsurance"
            :lineItems="chosenLoad.line_items"
            :originalCarrierArray="originalCarrierArray"
            :isPickup="isPickup"
            @handleIsPickup="handleIsPickup"
            @optionSelectedCarrier="optionSelectedCarrier"
            @optionSelectedTruck="optionSelectedTruck"
            class="mb-3"
          ></OptionsResponsive>
        </template>
        <template v-if="carriers.length === 0 && !allNotificationsObtained">
          <p>We are working to obtain carriers. This may take some time.</p>
          <b-spinner small label="Spinning"></b-spinner>
        </template>
        <div class="not-carriers">
          <p v-if="carriers.length === 0 && allNotificationsObtained">
            {{ $t("carriers.noCarriersFound") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DayJs from "dayjs";
import onReSize from "@/Extend/onResize";
import formatterCarrierName from "@/Extend/FormatCarrierName";
import CarriersList from "../../../../../Extend/CarriersList";
import Options from "./OptionsCarriersAndTrucks.vue";
import OptionsResponsive from "./OptionsCarriersAndTrucksResponsive.vue";
import UpdateAditionalInsurance from "../../../../../utils/updateAditionalInsurance";
import CarriersSorter from "./CarriersSorter.vue";
import CarrierMessage from "./CarrierMessage.vue";
import TrucksListSkeleton from "./TrucksListSkeleton.vue";

export default {
  name: "CarriersAndTrucks",
  mixins: [onReSize, formatterCarrierName, CarriersList],
  props: {
    carriers: Array,
    allNotificationsObtained: Boolean,
    filterByGuaranteed: Boolean,
    favoriteCarriersFilter: Boolean,
    originalCarrierArray: Array
  },
  components: {
    Options,
    OptionsResponsive,
    CarriersSorter,
    TrucksListSkeleton,
    CarrierMessage,
  },
  data() {
    return {
      aditionalInsurance: 35,
      carrierSelected: null,
      chosenLoad: null,
      loadingInsurance: false,
      rateCreationDate: null,
      carriersCopy: [],
      carriesAndTrucks: [],
      filteredCarriers: [],
      activeSpinner: false,
      isBranded: false,
      isPickup: false,
    };
  },
  async created() {
    await this.getCarriersThreshold();
    this.activeCompareAllOptions();
    const cargoValue = this.$store.getters["carriers/getChosenLoadCargoValue"];
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    this.aditionalInsurance = UpdateAditionalInsurance(cargoValue);
    this.getRateCreationDate();
    if (this.filterByGuaranteed) {
      this.filteredCarriers = this.filterCarriersByGuaranteed(this.carriers);
    }
    if (this.favoriteCarriersFilter) {
      this.filteredCarriers = this.filterCarriersByFavorite(this.carriers);
    }
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
  },
  computed: {
    ...mapGetters({
      chosenLoadCargoValue: "carriers/getChosenLoadCargoValue",
      carriersThresholds: "carriersAlert/getAllCarriersAlert",
      carriersImages: "carriers/getCarriersImages",
    }),
  },
  watch: {
    chosenLoadCargoValue() {
      this.aditionalInsurance = UpdateAditionalInsurance(this.chosenLoadCargoValue);
    },
    carriers: {
      handler() {
        this.filteredCarriers = [...this.carriers];
      },
      immediate: true,
    },
  },
  methods: {
    handleIsPickup(param) {
      this.isPickup = param;
    },
    getCarriersThreshold() {
      return this.$store.dispatch("carriersAlert/getAllCarriersAlert");
    },
    findCarrierImage(carrier) {
      if (carrier.logo) {
        return { url: carrier.logo, carrier: carrier.carrier || "" };
      }
      if (!carrier.name && carrier.carrier !== "Partial truck load") {
        return this.findTruckImage(carrier);
      }
      const newImage = this.carriersImages.find(
        (image) => image && image.name && carrier.carrier && image.name.includes(carrier.carrier)
      );
      if (newImage) {
        return newImage;
      }
      return { url: "" };
    },
    findTruckImage(truck) {
      const carrierImage = this.carriersImages.find((image) => {
        const imageName = image.name
          .slice(image.name.indexOf("/") + 1, -4)
          .toLowerCase()
          .trim();
        return imageName === truck.carrier.toLowerCase();
      });
      return { url: carrierImage?.url } || { url: "" };
    },
    getRateCreationDate() {
      if (this.carriers.length) {
        const carriersCreationDate = this.carriers[0].createdAt || new Date();
        this.rateCreationDate = DayJs(carriersCreationDate).format("MM/DD/YYYY");
      }
    },
    async optionSelectedCarrier(params) {
      this.isPickup = params.pickup;
      this.carrierSelected = params.carrierId;
      this.chosenLoad.insurance_required = params.isInsurance;
      this.chosenLoad.carrierName = params.carrierName;
      this.$store.commit("load/setChosenLoad", this.chosenLoad);
      if (params.changeView) {
        this.changeView("StopsInfo", params.loyaltyInsurance, params.pickup, params.isInsurance);
      }
      this.loadingInsurance = false;
    },
    async optionSelectedTruck(params) {
      this.loadingInsurance = true;
      // eslint-disable-next-line no-underscore-dangle
      this.carrierSelected = params.truckName._id;
      this.truckSelected = params.truckName;
      this.chosenLoad.insurance_required = params.isInsurance;
      this.$store.commit("load/setChosenLoad", this.chosenLoad);
      this.changeView("StopsInfo", params.loyaltyInsurance, params.isInsurance);
      this.loadingInsurance = false;
    },
    getFilteredCarriers(carriers) {
      this.filteredCarriers = [...carriers];
    },
    changeView(routeName, loyaltyInsurance, pickup, insurance) {
      const id = this.carrierSelected;
      this.$router.push({
        name: routeName,
        params: {
          loadId: this.$route.params.loadId,
          carrierId: id,
          pickup,
          insurance
        },
        query: {
          pickup,
          insurance,
          ...(loyaltyInsurance === true && { loyaltyInsurance: true })
        },
      });
    },
    activeCompareAllOptions(timeout = 500) {
      this.activeSpinner = true;
      setTimeout(() => {
        this.activeSpinner = false;
      }, timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.carriers-list {
  &__content {
    width: 98%;
    margin: 20px auto;
  }
  &__type {
    margin: 0px;
  }
  &__text-info {
    margin: 0px;
    text-align: justify;
  }
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    p {
      color: $color-primary-company;
      @include font-standart-text;
      font-weight: bold;
    }
    @media (max-width: 800px) {
      display: flex;
      align-items: flex-start;
      margin-bottom: 5%;
      &--option {
        border: none;
        font-weight: 600;
        @include font-small-button-text;
      }
      p {
        color: $color-primary-company;
        font-weight: bold;
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
}
.divider {
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $color-border-container;
  @media (max-width: 800px) {
    margin: 3% 0 4% 0;
  }
}
.carriers-container {
  @extend %scroll-bar-styles;
  overflow: auto;
  padding-right: 10px;
  @media (max-width: 800px) {
    max-height: none !important;
  }
}
.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 640px) {
    margin: 20px auto;
  }
}
::v-deep {
  .progress {
    height: 15px;
    background-color: #dbdfe3;
    width: 70%;
    @media (max-width: 640px) {
      width: 90%;
    }
  }
  .progress-bar {
    background-color: $color-progress-bar;
  }
}
.margin-spinner {
  margin: 4%;
}
</style>
