// eslint-disable-next-line max-len
import dayjs from "dayjs";
import QuoteAcceptanceModal from "../views/QuoteBook/CarrierSelection/carriersList/components/QuoteAcceptanceModal.vue";

export default {
  props: {
    truck: Object,
    truckImage: String,
    aditionalInsurance: Number,
    loadingInsurance: Boolean,
  },
  components: {
    QuoteAcceptanceModal,
  },
  data() {
    return {
      showModal: false,
      insurance: false,
    };
  },
  created() {},
  computed: {
    returnTransitTime() {
      if (this.truck.carrier === "305 EXPRESS USA (FTL)") {
        return "Same day delivery";
      }
      if (this.truck.transitTimeHours) {
        return `${this.truck.transitTimeHours} hours`;
      }
      return `${this.truck.transitTime} days`;
    },
  },
  methods: {
    selectedTruck(insurance) {
      this.insurance = insurance;
      this.showModal = true;
      const { description, insuranceAlert } = this.truck;
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "selectedTransport",
        value: this.truck,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "portalNotes",
        value: description,
      });
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "insuranceAlert",
        value: insuranceAlert,
      });
    },
    optionSelected({ enableLoyaltyInsurance, buyPickup, insurance }) {
      const params = {
        isInsurance: insurance,
        truckName: this.truck,
        loyaltyInsurance: enableLoyaltyInsurance,
        pickup: buyPickup
      };
      this.closeModal();
      this.$emit("optionSelected", params);
    },
    closeModal() {
      this.showModal = false;
    },
    dateExpired(expirationDate) {
      const today = new Date();
      return dayjs(today).isAfter(expirationDate);
    },
    checkIfCarrierHasExpired(truck) {
      if ("is_expired" in truck) {
        return truck.is_expired;
      }
      return this.dateExpired();
    },
  },
};
