export default {
  methods: {
    filterAndMergeEasyPostCarriers(carriers) {
      const mergedCarriers = {};
      carriers.forEach((item) => {
        const existingCarrier = mergedCarriers[item.quoteContractId];
        const hasParcelPickUpFee = item?.accesorials?.some(
          (accessorial) => accessorial?.Name?.toLowerCase() === "parcel pick up fee"
        );
        if (!existingCarrier || (existingCarrier && hasParcelPickUpFee)) {
          if (!hasParcelPickUpFee) {
            mergedCarriers[item.quoteContractId] = item;
          }
        }
      });
      return Object.values(mergedCarriers);
    },
  }
};
