export default {
  data() {
    return {
      screenWidth: null,
      carrierFilterOptions: [
        { value: "a", text: this.$t("carriers.fastest") },
        { value: "b", text: this.$t("carriers.price") },
      ],
      chosenFilterCarrier: this.$t("carriers.price"),
    };
  },
  watch: {
    carriers: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.carriers) {
          this.carriers = [];
        }
        this.selectedFilterCarrier(this.chosenFilterCarrier);
      },
    },
  },
  methods: {
    selectedFilterCarrier(optionFilter, carriers) {
      let filteredCarrier;
      this.chosenFilterCarrier = optionFilter;
      switch (this.chosenFilterCarrier) {
        case this.$t("carriers.fastest"):
          filteredCarrier = this.filterByFastest(carriers);
          this.isHandling = false;
          break;
        case this.$t("carriers.price"):
          filteredCarrier = this.filterByPrice(carriers);
          this.isHandling = false;
          break;
        case this.$t("carriers.handing"):
          this.isHandling = true;
          break;
        default:
          break;
      }
      return filteredCarrier;
    },
    filterByPrice(carrierList) {
      let filteredCarriers = [];
      filteredCarriers = carrierList || [...this.carriers];
      filteredCarriers.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      this.$emit("getFilteredCarriers", filteredCarriers);
    },
    filterByFastest() {
      let filteredCarriers = [];
      filteredCarriers = [...this.carriers];
      const carriersWithTransitHours = filteredCarriers.filter(
        (carrier) => carrier.transitTimeHours
      );
      const carriersWithTransitDays = filteredCarriers.filter(
        (carrier) => !carrier.transitTimeHours
      );
      filteredCarriers = [
        ...this.sortByTransitTime(carriersWithTransitHours, "transitTimeHours"),
        ...this.sortByTransitTime(carriersWithTransitDays, "transitTime"),
      ];
      this.$emit("getFilteredCarriers", filteredCarriers);
    },
    sortByTransitTime(carriers, key) {
      return carriers.sort((a, b) => {
        const transitTimeA = a[key];
        const transitTimeB = b[key];
        return parseFloat(transitTimeA) - parseFloat(transitTimeB);
      });
    },
  },
};
