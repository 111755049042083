<template>
  <div class="send-quote-files-modal">
    <b-overlay :show="loading" rounded="sm" opacity="0.85">
      <div class="send-quote-files-modal__content">
        <div class="send-quote-files-modal__header">
          <div class="send-quote__title">
            <h3>Send quote</h3>
          </div>
          <div class="send-quote__button">
            <button @click="$emit('input', false)" type="button" class="close-button">
              <i class="ion ion-close-circle-outline close-button__icon"></i>
            </button>
          </div>
        </div>
        <hr />
        <div class="send-quote-files-modal__input-container">
          <h5 class="title-content">Email</h5>
          <Chips
            v-model.trim="modalForm.emails"
            :class="emptyFields['emails'] === true ? 'missing-field' : ''"
            class="input-information-email"
            :placeholder="$t('claimOrDispute.email')"
            :validateEmails="true"
            :suggestions="chipsEmails"
            @input="analyzeValidEmails, handleMissingStyles($event, 'emails')"
          />
        </div>
        <h5 class="title-content">Subject</h5>
        <RCInput
          :class="emptyFields['subject'] === true ? 'missing-field' : ''"
          name=""
          label="Subject"
          class="send-quote-files-modal__textarea--subject"
          v-model="modalForm.subject"
          @blur="handleMissingStyles(modalForm.subject, 'subject')"
        />
        <div class="send-quote-files-modal__input-container">
          <h5 class="title-content">Message</h5>
          <textarea
            :class="emptyFields['mail_content'] === true ? 'missing-field' : ''"
            name=""
            placeholder="Type your message..."
            class="send-quote-files-modal__textarea send-quote-files-modal__textarea--content"
            @change="handleMissingStyles($event.target.value, 'mail_content')"
            v-model="modalForm.mail_content"
          ></textarea>
        </div>
        <CarriersMarkups ref="carriersMarkups" class="mt-2" />
        <div class="d-flex flex-wrap">
          <BuyRate ref="buyRate" class="mr-4 mt-3 mb-2" />
          <ApplyMarkupsInsurance ref="markupsInsurance" class="mt-3 mb-2" />
        </div>
        <div class="carriers-list-container">
          <CarriersModalList
            :carriersList="carriersList"
            ref="carriersModalList"
            class="carriers-list-component mb-2"
          />
        </div>
        <FormatDocumentType v-model="typeOfFormat" class="mt-3" />
        <div class="button-container" v-if="$options.filters.checkPermission('post:quote-file')">
          <button class="button-send-document" @click="sendQuoteFile()">Send Quote File</button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import ValidateEmail from "../../../../utils/EmailValidation";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import CarriersMarkups from "./components/CarriersMarkups.vue";
import BuyRate from "./components/BuyRate.vue";
import CarriersModalList from "./components/CarriersModalList.vue";
import StorageEmails from "../../../../Extend/StorageEmails";
import FormatDocumentType from "./components/FormatDocumentType.vue";
import Chips from "../../../../components/Chips.vue";
import ApplyMarkupsInsurance from "./components/ApplyMarkupsInsurance.vue";

export default {
  name: "SendQuoteFilesModal",
  components: {
    Chips,
    FormatDocumentType,
    CarriersMarkups,
    BuyRate,
    CarriersModalList,
    RCInput,
    ApplyMarkupsInsurance
  },
  mixins: [StorageEmails],
  props: {
    carriersList: Array,
  },
  data() {
    return {
      loading: false,
      emails: [],
      modalForm: {
        emails: [],
        mail_content: "",
        Query: " ",
        subject: null,
      },
      emptyFields: {
        emails: [],
        mail_content: null,
        Query: "",
        subject: null,
      },
      validEmails: false,
      chipsEmails: [],
      typeOfFormat: "",
    };
  },
  computed: {
    // ...mapGetters({ carriersList: "carriers/getCarriers" }),
  },
  created() {
    this.chipsEmails = JSON.parse(localStorage.getItem("portal-emails")) || [];
  },
  methods: {
    quoteComponentsValidation() {
      const quoteFileForm = this.getQuoteFileForm();
      if (quoteFileForm.exclude_carriers.length === this.carriersList.length) {
        this.swal({
          title: "Please select at least one carrier",
          icon: "warning",
        });
        return false;
      }
      return quoteFileForm;
    },
    async sendQuoteFile() {
      if (!this.$options.filters.checkPermission("post:quote-file")) {
        return;
      }
      if (!this.validateFormfields()) {
        return;
      }
      const quoteFileForm = this.quoteComponentsValidation();
      if (!quoteFileForm) {
        return;
      }
      this.deleteInvalidEmails();
      this.loading = true;
      const data = {
        loadId: this.$route.params.loadId,
        body: {
          email: [...this.modalForm.emails],
          mail_content: this.modalForm.mail_content,
          Query: [this.modalForm.Query],
          subject: this.modalForm.subject,
        },
        params: quoteFileForm
      };
      if (this.typeOfFormat === "pdf") {
        await this.$store.dispatch("quoteFile/sendQuoteFile", data);
      } else {
        await this.$store.dispatch("quoteFile/sendQuoteFileExcel", data);
      }
      this.saveEmailsInLocalStorage(this.modalForm.emails);
      this.loading = false;
      this.$emit("input", false);
    },
    validateFormfields() {
      const validatedForm = ValidateEmptyFields(this.modalForm);
      this.resetEmptyFieldsValues();
      const validateEmail = this.modalForm.emails.filter((email) => ValidateEmail(email));
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (validateEmail.length && validatedForm.validation) {
        return true;
      }
      return false;
    },
    getQuoteFileForm() {
      const markups = this.$refs.carriersMarkups.getMarkups();
      const quoteFileForm = {
        load_id: this.$route.params.loadId,
        markup_percent: markups.markup_percent,
        markup_min: markups.markup_min,
        exclude_carriers: this.$refs.carriersModalList.getCarriersList(),
        include_buy_rate: this.$refs.buyRate.includeBuyRate,
        markup_insurance: this.$refs.markupsInsurance.applyMarkupsInsurance
      };
      return quoteFileForm;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    deleteInvalidEmails() {
      this.modalForm.emails = this.modalForm.emails.filter((email) => ValidateEmail(email));
    },
    analyzeValidEmails() {
      const emails = this.body.emails.filter((email) => ValidateEmail(email));
      if (emails.length > 0) {
        this.validEmails = false;
      } else {
        this.validEmails = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";

.close-button {
  @include modals-close-button;
}
.title-content {
  color: $color-primary-company;
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 2px;
}
.input-information-email {
  @include font-small-standart-text;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  @include input-information;
  border: 1px solid $color-border-container;
  width: 100%;
  box-shadow: none;
  padding: 0px;
  border-radius: 10px;
  color: black;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.send-quote__title {
  @include modals-title;
}
hr {
  margin-top: 0;
}
.send-quote-files-modal {
  font-family: $font-family-portal;
  &__header {
    @include modals-header;
  }
  &__input {
    @include input-information;
    border: 1px solid $color-border-container;
    height: 33px;
    width: 100%;
    padding-left: 10px;
    box-shadow: none;
    border-radius: 10px;
    color: black;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
  &__textarea {
    width: 100%;
    padding: 8px;
    border-radius: 14px;
    border: 1px solid $color-border-container;
    outline: none;
    background: #ffffff;
    font-size: 14px;
    resize: none;
    &--content {
      height: 70px;
    }
  }
}
.input-container {
  border: 1px solid $color-border-container;
}

.missing-field {
  background-color: $missing-fields;
  border-radius: 10px;
}

.document-container {
  display: flex;
  justify-content: space-between;
  color: $color-primary-company;
  align-items: center;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__icon {
    &-pdf {
      color: #f40f02;
    }
    &-delete {
      cursor: pointer;
      color: $color-primary-company;
    }
  }
}
.button-container {
  text-align: center;
  margin: 20px auto;
}
.button-send-document {
  @include primary-button(35px);
  @include font-button-text;
  display: flex;
  justify-content: center;
  margin: 13px auto 0px;
  align-items: center;
}
::v-deep {
  .el-upload {
    display: flex;
    @media (max-width: 800px) {
      justify-content: flex-end;
    }
  }
  .el-upload-dragger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    border: 1px dashed $color-border-container;
    i {
      color: $color-border-container;
      margin: auto;
    }
  }
  .chips-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input-information-email {
    height: auto;
    padding: 5px;
  }
}

::v-deep .spinner-border {
  color: $color-primary-company;
}
.missing-field .send-quote-files-modal__textarea {
  background-color: $missing-fields;
}
::v-deep .missing-field .chips-container__input {
  background-color: $missing-fields;
}
::v-deep .missing-field .chips {
  background-color: $missing-fields;
}
::v-deep .missing-field .el-upload .el-upload-dragger {
  background-color: $missing-fields;
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
}

::v-deep {
  .rc-input-container {
    font-size: 14px;
    input {
      font-size: 14px;
    }
    label {
      top: 9px;
    }
  }
}
</style>
