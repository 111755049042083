<template>
  <div class="carriers-list mt-2">
    <CarriersSorter
      :carriers="carriers"
      @getFilteredCarriers="getFilteredCarriers"
    ></CarriersSorter>
    <span class="divider"></span>
    <div class="carriers-list__content">
      <div class="carriers-list__title">
        <p class="carriers-list__type">{{ $t("carriers.ltlOptions") }}</p>
        <p v-if="rateCreationDate" class="carriers-list__type">
          <small>Last rate creation date: {{ rateCreationDate }}</small>
        </p>
      </div>
    </div>
    <div class="carriers-container" id="carriers-container" v-if="screenWidth > 800">
      <template v-if="carriersImages && chosenLoad">
        <Options
          v-for="(carrier, index) in filteredCarriers"
          :key="index"
          :carrier="carrier"
          :carrierImage="findCarrierImage(carrier)"
          :aditionalInsurance="aditionalInsurance"
          :loadingInsurance="loadingInsurance"
          :lineItems="chosenLoad.line_items"
          :originalCarrierArray="originalCarrierArray"
          :isPickup="isPickup"
          @optionSelected="optionSelected"
          @handleIsPickup="handleIsPickup"
          class="mb-3"
        ></Options>
      </template>
      <template v-if="carriers.length === 0 && !allNotificationsObtained">
        <p>We are working to obtain carriers. This may take some time.</p>
        <b-spinner small label="Spinning"></b-spinner>
      </template>
      <div class="not-carriers">
        <p v-if="carriers.length === 0 && allNotificationsObtained">
          {{ $t("carriers.noCarriersFound") }}
        </p>
      </div>
    </div>
    <div class="carriers-container" id="carriers-container" v-if="screenWidth <= 800">
      <template v-if="carriersImages && chosenLoad">
        <OptionsResponsive
          v-for="(carrier, index) in filteredCarriers"
          :key="index"
          :optionLTL="index"
          :carrier="carrier"
          :carrierImage="findCarrierImage(carrier)"
          :aditionalInsurance="aditionalInsurance"
          :loadingInsurance="loadingInsurance"
          :lineItems="chosenLoad.line_items"
          :originalCarrierArray="originalCarrierArray"
          :isPickup="isPickup"
          @optionSelected="optionSelected"
          @handleIsPickup="handleIsPickup"
          class="mb-3"
        >
        </OptionsResponsive>
      </template>
      <template v-if="carriers.length === 0 && !allNotificationsObtained">
        <p>We are working to obtain carriers. This may take some time.</p>
        <b-spinner small label="Spinning"></b-spinner>
      </template>
      <div class="not-carriers">
        <p v-if="carriers.length === 0 && allNotificationsObtained">
          {{ $t("carriers.noCarriersFound") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DayJs from "dayjs";
import FilterCarriers from "@/Extend/FilterCarriers";
import onReSize from "@/Extend/onResize";
import formatterCarrierName from "@/Extend/FormatCarrierName";
import CarriersList from "../../../../../../../Extend/CarriersList";
import Options from "./OptionsCarriers.vue";
import OptionsResponsive from "./OptionsCarriersResponsive.vue";
import UpdateAditionalInsurance from "../../../../../../../utils/updateAditionalInsurance";
import CarriersSorter from "../../CarriersSorter.vue";

export default {
  name: "Carriers",
  mixins: [onReSize, formatterCarrierName, FilterCarriers, CarriersList],
  props: {
    carriers: Array,
    allNotificationsObtained: Boolean,
    filterByGuaranteed: Boolean,
    favoriteCarriersFilter: Boolean,
    originalCarrierArray: Array,
  },
  components: {
    Options,
    OptionsResponsive,
    CarriersSorter,
  },
  data() {
    return {
      aditionalInsurance: 35,
      carrierSelected: null,
      chosenLoad: null,
      loadingInsurance: false,
      rateCreationDate: null,
      filteredCarriers: [],
      isPickup: false
    };
  },
  async created() {
    await this.getCarriersThreshold();
    const cargoValue = this.$store.getters["carriers/getChosenLoadCargoValue"];
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    this.aditionalInsurance = UpdateAditionalInsurance(cargoValue);
    this.getRateCreationDate();
    if (this.filterByGuaranteed) {
      this.filteredCarriers = this.filterCarriersByGuaranteed(this.carriers);
    }
    if (this.favoriteCarriersFilter) {
      this.filteredCarriers = this.filterCarriersByFavorite(this.carriers);
    }
  },
  computed: {
    ...mapGetters({
      chosenLoadCargoValue: "carriers/getChosenLoadCargoValue",
      carriersThresholds: "carriersAlert/getAllCarriersAlert",
      carriersImages: "carriers/getCarriersImages",
    }),
  },
  watch: {
    chosenLoadCargoValue() {
      this.aditionalInsurance = UpdateAditionalInsurance(this.chosenLoadCargoValue);
    },
    carriers: {
      handler() {
        this.filteredCarriers = [...this.carriers];
      },
      immediate: true,
    },
  },
  methods: {
    getCarriersThreshold() {
      return this.$store.dispatch("carriersAlert/getAllCarriersAlert");
    },
    handleIsPickup(param) {
      this.isPickup = param;
    },
    getRateCreationDate() {
      if (this.carriers.length) {
        const carriersCreationDate = this.carriers[0].createdAt || new Date();
        this.rateCreationDate = DayJs(carriersCreationDate).format("MM/DD/YYYY");
      }
    },
    async optionSelected(params) {
      this.isPickup = params.pickup;
      this.carrierSelected = params.carrierId;
      this.chosenLoad.insurance_required = params.isInsurance;
      this.chosenLoad.carrierName = params.carrierName;
      this.$store.commit("load/setChosenLoad", this.chosenLoad);
      if (params.changeView) {
        this.$store.commit("carriers/disconnectSocket");
        this.changeView("StopsInfo", params.loyaltyInsurance, params.pickup, params.isInsurance);
      }
      this.loadingInsurance = false;
    },
    findCarrierImage(carrier) {
      if (carrier.logo) {
        return { url: carrier.logo, carrier: carrier.carrier };
      }
      const newImage = this.carriersImages.find(
        (image) => image && image.name &&
          carrier.name &&
          image.name.toLowerCase().includes(carrier.name.toLowerCase())
      );
      if (newImage) {
        return newImage;
      }
      return { url: "" };
    },
    getFilteredCarriers(carriers) {
      this.filteredCarriers = [...carriers];
    },
    changeView(routeName, loyaltyInsurance, pickup, insurance) {
      const id = this.carrierSelected;
      this.$router.push({
        name: routeName,
        params: {
          loadId: this.$route.params.loadId,
          carrierId: id,
          pickup,
          insurance,
        },
        query: {
          pickup,
          insurance,
          ...(loyaltyInsurance === true && { loyaltyInsurance: true })
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carriers-list {
  &__content {
    width: 98%;
    margin: 20px auto;
  }
  &__type {
    margin: 0px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    p {
      color: $color-primary-company;
      @include font-standart-text;
      font-weight: bold;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 5%;
      &--option {
        border: none;
        font-weight: 600;
        @include font-small-button-text;
      }
      p {
        color: $color-primary-company;
        font-weight: bold;
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
}

.divider {
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $color-border-container;
  @media (max-width: 800px) {
    margin: 3% 0 4% 0;
  }
}

.carriers-container {
  @extend %scroll-bar-styles;
  overflow: auto;
  padding-right: 10px;
  @media (max-width: 800px) {
    max-height: none !important;
  }
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 640px) {
    margin: 20px auto;
  }
}

::v-deep {
  .progress {
    height: 15px;
    background-color: #dbdfe3;
    width: 70%;
    @media (max-width: 640px) {
      width: 90%;
    }
  }
  .progress-bar {
    background-color: $color-progress-bar;
  }
}
</style>
