export default {
  methods: {
    saveEmailsInLocalStorage(emails) {
      const emailsLocalStorage = JSON.parse(localStorage.getItem("portal-emails"));
      if (emailsLocalStorage) {
        emailsLocalStorage.forEach((email) => {
          if (!emails.includes(email)) {
            emails.push(email);
          }
        });
      }
      localStorage.setItem("portal-emails", JSON.stringify(emails));
    },
  },
};
