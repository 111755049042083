<template>
  <div class="format-document">
    <label class="input-content">
      <input type="radio" name="format" value="pdf" v-model="selectedFormat" /> PDF Format
    </label>
    <label class="input-content">
      <input type="radio" name="format" value="excel" v-model="selectedFormat" /> Excel Format
    </label>
  </div>
</template>

<script>
export default {
  name: "FormatDocument",
  data() {
    return {
      selectedFormat: "pdf",
    };
  },
  watch: {
    selectedFormat: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("input", this.selectedFormat);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.format-document {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-family: $font-family-portal;
  color: $color-primary-company;
}
.input-content {
  font-weight: 600;
}
</style>
