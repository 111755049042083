<template>
  <div class="option-container accordion" role="tablist">
    <div
      class="option-container__info"
      @click="collapse()"
      v-b-toggle="'collapse' + truck._id"
      role="tab"
    >
      <div class="image-container">
        <img
          class="image-container__image"
          :src="truckImage ? truckImage : truck.logo"
          :alt="truck.carrier"
        />
        <!-- <p class="name-container">{{ truck.carrier }}</p> -->
      </div>
      <div class="option-container__info__description">
        <div class="option-container__item option-container__item--restrictions">
          <span> {{ truck.carrier }} </span>
        </div>
        <div class="option-container__item option-container__item--restrictions">
          <span> Transit time: {{ returnTransitTime }} </span>
        </div>
      </div>
      <div class="collapse-button">
        <button class="collapse-button__button" :class="isCollapsed">
          <i class="ion ion-chevron-forward-outline collapse-button__icon collapse-icon"></i>
        </button>
      </div>
    </div>
    <b-collapse
      :id="'collapse' + truck._id"
      class="option-container__collapse"
      accordion="my-accordion"
      role="tabpanel"
    >
      <div>
        <span class="option-container__collapse__title-liability">
          <strong class="mr-1">Service class:</strong>
          {{ truck.serviceClass }}
        </span>
        <span class="option-container__collapse__title-liability">
          <strong class="mr-1">Quote Expiration Date:</strong>
          {{ truck.expiration | dateFormat }}
        </span>
        <span v-if="truck.alert" v-b-popover.hover="truck.description" class="warning-alert-icon">
          <i class="ion ion-warning-outline"></i>
          Tap to see service threshold
        </span>
      </div>
    </b-collapse>
    <div class="option-container__item option-container__book-buttons">
      <button
        class="quote-button quote-button--green"
        @click="selectedTruck(false)"
        :class="checkIfCarrierHasExpired(truck) || loadingInsurance ? 'disabled-button' : ''"
        :disabled="checkIfCarrierHasExpired(truck) || loadingInsurance"
        v-b-popover.hover.top="checkIfCarrierHasExpired(truck) ? $t('carriers.priceExpired') : ''"
      >
        <span v-if="!loadingInsurance">{{ $t("carriers.book") }}</span>
        <span v-if="!loadingInsurance">USD{{ truck.price.toFixed(2) | priceToUsd }}</span>
        <b-spinner small v-if="loadingInsurance" variant="light" label="Spinning"></b-spinner>
      </button>
      <button
        class="quote-button quote-button--blue"
        @click="selectedTruck(true)"
        :class="checkIfCarrierHasExpired(truck) || loadingInsurance ? 'disabled-button' : ''"
        :disabled="checkIfCarrierHasExpired(truck) || loadingInsurance"
        v-b-popover.hover.top="checkIfCarrierHasExpired(truck) ? $t('carriers.priceExpired') : ''"
      >
        <span v-if="!loadingInsurance" class="ellipsis-text">{{
          $t("carriers.bookWithInsurance")
        }}</span>
        <span v-if="!loadingInsurance">
          USD{{ (truck.price + aditionalInsurance).toFixed(2) | priceToUsd }}
        </span>
        <b-spinner small v-if="loadingInsurance" variant="light" label="Spinning"></b-spinner>
      </button>
    </div>
    <b-modal v-model="showModal" hide-header hide-footer size="lg" no-close-on-backdrop centered>
      <QuoteAcceptanceModal
        :insurance="insurance"
        :aditionalInsurance="aditionalInsurance"
        :carrier="truck"
        @closeModal="closeModal"
        @optionSelected="optionSelected"
      />
    </b-modal>
  </div>
</template>

<script>
import OptionsTrucks from "@/Extend/OptionsTrucks";

export default {
  name: "OptionsTruckResponsive",
  mixins: [OptionsTrucks],
  data() {
    return {
      isCollapsed: "is-collapsed",
    };
  },
  methods: {
    collapse() {
      if (this.isCollapsed === "is-collapsed") {
        this.isCollapsed = "not-collapsed";
      } else {
        this.isCollapsed = "is-collapsed";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.option-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 20px;
  background: white;
  padding: 5%;
  &__item {
    display: flex;
    text-align: start;
    color: $color-primary-company;
    font-weight: bold;
    &--restrictions {
      flex-direction: column;
    }
    p {
      font-weight: normal;
    }
    span {
      font-weight: bold;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    &__description {
      p {
        margin: 0 !important;
      }
    }
  }
  &__book-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__collapse {
    width: 100%;
    border-top: 2px solid $color-gray-dark;
    margin-top: 1%;
    margin-bottom: 3%;
    padding: 5% 2% 0 2%;
    text-align: left;
    &__title-liability {
      color: $color-primary-company;
      display: flex;
      flex-direction: row;
      p {
        font-weight: 400;
        margin-left: 8px;
      }
    }
    &__warning {
      font-weight: normal;
    }
    &__restrictions {
      color: orange;
      text-decoration: underline;
      font-style: italic;
    }
  }
}
.image-container {
  padding: 10px;
  &__image {
    max-width: 150px;
    width: 100%;
  }
}
.name-container {
  text-align: center;
  color: #828282;
  margin: 0;
}
.collapse-button {
  &__button {
    border: none;
    background: transparent;
  }
  &__icon {
    color: $color-primary-company;
    font-size: 2rem;
  }
}
.quote-button {
  width: 100%;
  margin: 5px auto;
  padding: 2% 3%;
  height: 45px;
  border-radius: 10px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
  @media (max-width: 500px) {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 62%;
    }
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.collapse-button {
  transform: rotate(0deg);
  transition: 0.3s;
}
.collapse-button .not-collapsed {
  transform: rotate(90deg);
  transition: 0.3s;
}
.warning-alert-icon i {
  color: #e6bb6f;
  font-size: 23px;
}
</style>
