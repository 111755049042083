<template>
  <div :class="lineItemsContainer">
    <div class="lineItems-group">
      <div class="lineItems-group__container-lineItems">
        <div v-for="(item, index) in selectedLineItems" class="lineItems-group__info" :key="index">
          <div class="lineItems-group__title-container">
            <h6 class="lineItems-group__title">
              {{ $t("myLoads.myLoadsDetail.line") }} {{ index + 1 }}
            </h6>
          </div>
          <span class="lineItems-group__text" v-if="item.description !== null">
            <b> {{ $t("myLoads.myLoadsDetail.description") }}: </b>
            {{ item.description }}
          </span>
          <span class="lineItems-group__text" v-if="item.handling_unit_count !== null">
            <b> {{ $t("myLoads.myLoadsDetail.handlingCount") }}: </b>
            {{ item.handling_unit_count }} ({{ item.handling_units }})
          </span>
          <span class="lineItems-group__text">
            <b> {{ $t("myLoads.myLoadsDetail.dimensions") }}: </b>
            {{ ceilDecimals(item.length) }} X {{ ceilDecimals(item.width) }} X
            {{ ceilDecimals(item.height) }} ({{ item.dimension_units }})
          </span>
          <span class="lineItems-group__text" v-if="item.weight !== null">
            <b> {{ $t("myLoads.myLoadsDetail.weight") }}: </b>
            {{ item.weight }} ({{ item.weight_units }})
          </span>
          <span class="lineItems-group__text" v-if="item.nmfc_class !== null">
            <b> {{ $t("myLoads.myLoadsDetail.nmfcClass") }}: </b>
            {{ item.nmfc_class }}
          </span>
          <span class="lineItems-group__text" v-if="item.nmfc_number !== null">
            <b>NMFC number:</b>
            {{ item.nmfc_number }}({{ item.nmfc_class }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineItems",
  props: {
    selectedLineItems: Array,
  },
  data() {
    return {
      lineItemsContainer: null,
    };
  },
  created() {
    if (this.$route.name.toLowerCase() === "loadsdetails") {
      this.lineItemsContainer = "lineItems-container";
    }
  },
  methods: {
    ceilDecimals(decimal) {
      return Math.ceil(decimal);
    },
  },
};
</script>

<style lang="scss" scoped>
.lineItems-container {
  padding: 1% 7%;
  background-color: $color-gray-light;
  color: $color-primary-company;
  font-family: $font-family-portal;
}

// .lineItems-group {
//   &__title-container {
//     text-align: start;
//   }
//   &__info {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     // padding-left: 4%;
//   }
//   &__text {
//     text-align: left;
//   }
//   &__title {
//     margin-top: 2%;
//     color: $color-border-container;
//     font-weight: bold;
//   }
// }

.lineItems-group {
  &__title-container {
    text-align: start;
  }
  &__info {
    display: grid;
    justify-items: flex-start;
  }
  &__container-lineItems {
    color: $color-primary-company;
    display: flex;
    flex-direction: column;
  }
  &__text {
    text-align: left;
  }
  &__title {
    margin: 15px 0px;
    color: $color-border-container;
    font-weight: bold;
  }
}
</style>
