export default {
  watch: {
    filterByGuaranteed(value) {
      if (value) {
        this.filteredCarriers = this.filterCarriersByGuaranteed(this.carriers);
      } else {
        this.filteredCarriers = [...this.carriers];
      }
    },
  },
  methods: {
    filterCarriersByGuaranteed(carriers) {
      return carriers.filter((carrier) => {
        const serviceClass = carrier.serviceClass.toLowerCase();
        const serviceClassList = ["guaranteed", "expedited", "time critical", "accelerated"];
        return serviceClassList.some((item) => serviceClass.includes(item));
      });
    },
    filterCarriersByFavorite(carriers) {
      return carriers.filter((carrier) => carrier.fav);
    }
  },
};
