<template>
  <div class="markups-insurance">
    <label class="markups-insurance__title">Apply markup to insurance value</label
    ><b-form-checkbox class="markups-insurance__checkbox" v-model="applyMarkupsInsurance" />
  </div>
</template>

<script>
export default {
  name: "ApplyMarkupToInsurance",
  data() {
    return {
      applyMarkupsInsurance: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.markups-insurance {
  display: flex;
  align-items: center;
  gap: 20px;
  &__title {
    margin: 0px;
    color: $color-primary-company;
    font-weight: 600;
  }
}
</style>
