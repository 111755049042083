export default function downloadDocument(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // eslint-disable-next-line no-shadow
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.dispatchEvent(new MouseEvent("click"));
      window.URL.revokeObjectURL(url);
    });
}
