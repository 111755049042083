<template>
  <div class="markups-form-content">
    <b-overlay :show="loading" rounded="sm">
      <div :class="{ disablecomponent: disableButtons }">
        <div class="input-container">
          <div class="input-content">
            <span class="input-content__text">Min:</span>
            <input
              id="min-markup"
              type="number"
              :disabled="loading"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              v-model="markups.markup_min"
              @input="validateMarkupsApplied()"
              @change="validateMarkupsApplied()"
              @blur="validateMarkupsApplied()"
              class="input-content__input"
            />
          </div>
          <div class="input-content">
            <span class="input-content__text">Percent:</span>
            <input
              type="number"
              :disabled="loading"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              v-model="markups.markup_percent"
              @input="validateMarkupsApplied()"
              @change="validateMarkupsApplied()"
              @blur="validateMarkupsApplied()"
              class="input-content__input"
            />
          </div>
        </div>
        <em class="markups-form-content__info">Add markups to recalculate rates</em> <br />
        <span
          class="markups-form-content__info markups-form-content__info--warning"
          v-if="!markupsHasBeenApplied && !loading"
          >Please be aware no markup has been applied yet, please click here to update your price by
          adding your markup fee.</span
        >
        <button
          class="markups-button"
          type="button"
          @click="applyMarkupsToCarriersPrice()"
          :class="{ disabled: loading, disabled: markupsHasBeenApplied }"
          :disabled="loading || markupsHasBeenApplied"
        >
          <span>APPLY MARKUPS</span>
        </button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Markups",
  props: {
    disableButtons: Boolean,
  },
  data() {
    return {
      loading: true,
      markups: {
        markup_min: 0,
        markup_percent: 0,
      },
      markupsHasBeenApplied: false,
      fistTimeExecuted: true
    };
  },
  computed: {
    ...mapGetters({
      carriers: "carriers/getCarriers",
      appliedMarkups: "load/getAppliedMarkups",
    }),
  },
  watch: {
    markups: {
      deep: true,
      handler() {
        this.markups.markup_min = parseInt(this.markups.markup_min, 10);
        this.markups.markup_percent = parseInt(this.markups.markup_percent, 10);
      },
    },
    carriers: {
      deep: true,
      handler() {
        this.setLoadMarkups();
        this.loading = false;
      },
    },
  },
  created() {
    this.markups = JSON.parse(localStorage.getItem("magaya-markups"));
  },
  methods: {
    setLoadMarkups() {
      // If load markups are greater than 0, set load markups as default
      const loadMarkups = {
        markup_min: this.carriers.length ? this.carriers[0].markupMin : 0,
        markup_percent: this.carriers.length ? this.carriers[0].markupPercent : 0,
      };
      this.markupsHasBeenApplied = Object.keys(loadMarkups).some((key) => loadMarkups[key] > 0);
      if (this.markupsHasBeenApplied) {
        Object.assign(this.markups, loadMarkups);
        this.setMarkupsInVuex();
        return;
      }
      this.setMarkupsInVuex({
        markup_min: 0,
        markup_percent: 0,
      });
    },
    async applyMarkupsToCarriersPrice() {
      this.loading = true;
      this.markups.markup_min = parseInt(this.markups.markup_min, 10);
      this.markups.markup_percent = parseInt(this.markups.markup_percent, 10);
      const payload = {
        loadId: this.$route.params.loadId,
        markups: this.markups,
      };
      await this.$store.dispatch("load/updateLoadMarkups", payload);
      this.markupsHasBeenApplied = true;
      this.setMarkupsInVuex();
      this.loading = false;
    },
    setMarkupsInVuex(defaultMarkups) {
      const markupsToSet = defaultMarkups ? { ...defaultMarkups } : { ...this.markups };
      this.$store.commit("load/setAppliedMarkups", {
        markups: markupsToSet,
        markupsHasBeenApplied: this.markupsHasBeenApplied,
      });
    },
    validateMarkupsApplied() {
      if (!this.appliedMarkups.markups) return;
      if (
        this.markups.markup_min !== this.appliedMarkups.markups.markup_min ||
        this.markups.markup_percent !== this.appliedMarkups.markups.markup_percent
      ) {
        this.markupsHasBeenApplied = false;
      } else {
        this.markupsHasBeenApplied = true;
      }
      // this.setMarkupsInVuex();
    },
  },
};
</script>

<style lang="scss" scoped>
.markups-form-content {
  margin-bottom: 20px;
  &__label {
    margin-bottom: 0px;
  }
  .input-content {
    width: 100%;
    display: flex;
    &__input {
      width: 100%;
    }
    input:disabled {
      background: none;
    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  &__info {
    font-weight: 400;
    font-size: 12px;
    margin: 7px 0px;
    &--warning {
      font-size: 10.1px;
      font-weight: bold;
    }
  }
}

.markups-button {
  @include primary-button(35px);
  height: 35px;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  font-weight: 600;
  margin: none !important;
  &.disabled {
    background-color: $color-gray;
    border: none;
    color: $color-white;
    cursor: initial;
    &:hover {
      background-color: $color-gray;
    }
  }
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.input-content {
  margin-top: 5px;
  padding: 5px;
  padding-left: 10px;
  background: $color-white;
  border: 1px solid $color-border-container;
  border-radius: 15px;
  @include font-input-text;
  &__input {
    color: $color-primary-company;
    border: none;
    outline: none;
    margin-left: 10px;
  }
}

.disablecomponent {
  inset: 0px;
  opacity: 0.45;
  backdrop-filter: blur(2px);
  background-color: #efefef !important;
  pointer-events: none;
}

::v-deep {
  .el-loading-mask {
    border-radius: 15px;
  }
}
</style>
