<template>
  <div class="carriers-markups">
    <div class="carriers-markups__input-container carriers-markups__input-container--percent">
      <label class="carriers-markups__label">Apply Markup:</label>
      <input
        :class="{
          'missing-fields': emptyFields.markup_percent,
        }"
        class="carriers-markups__input"
        v-model="form.markup_percent"
        placeholder="%"
      />
    </div>
    <div class="carriers-markups__input-container carriers-markups__input-container--min">
      <label class="carriers-markups__label">Min:</label>
      <input
        :class="{
          'missing-fields': emptyFields.markup_min,
        }"
        class="carriers-markups__input"
        v-model="form.markup_min"
        placeholder="$"
      />
    </div>
    <button class="carriers-markups__button" @click="applyMarkups">Apply</button>
  </div>
</template>

<script>
export default {
  name: "CarriersMarkups",
  data() {
    return {
      form: {
        markup_min: "",
        markup_percent: "",
      },
      emptyFields: {
        markup_percent: false,
        markup_min: false,
      },
    };
  },
  methods: {
    getMarkups() {
      this.parseToNumber(this.form);
      return this.form;
    },
    parseToNumber(form) {
      Object.keys(form).forEach((key) => {
        form[key] = form[key] === "" ? 0 : parseInt(form[key], 10);
      });
      return form;
    },
    applyMarkups() {
      if (!this.form.markup_min || !this.form.markup_percent) {
        this.emptyFields.markup_min = this.form.markup_min === "";
        this.emptyFields.markup_percent = this.form.markup_percent === "";
        return;
      }
      const markups = this.parseToNumber(this.form);
      this.$store.commit("quoteFile/setMarkups", markups);
    },
  },
};
</script>

<style lang="scss" scoped>
.carriers-markups {
  display: flex;
  gap: 15px;
  &__input-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__input {
    width: 60px;
    height: 35px;
    border: 1px solid $color-border-container;
    border-radius: 12px;
    outline: none;
    padding-left: 3px;
  }
  &__label {
    margin: 0px;
    color: $color-primary-company;
    font-weight: 600;
  }
  &__button {
    @include primary-button(35px);
    width: 100px;
    font-weight: 500;
  }
}

.missing-fields {
  background-color: $missing-fields;
}
</style>
