<template>
  <div class="carriers-list">
    <div class="carriers-list__sortby">
      <span class="carriers-list__sortby-title">
        {{ this.$t("myLoads.myLoadsTable.sortBy") }}
      </span>
      <Dropdown
        class="carriers-list__filters--option border-right-filter"
        :options="carrierFilterOptions"
        optionLabel="text"
        optionValue="value"
        :placeholder="chosenFilterCarrier"
        :showToggleAll="false"
      >
        <template #option="slotProps">
          <div class="p-dropdown-car-option">
            <span
              class="filter-item"
              :class="chosenFilterCarrier === slotProps.option.text ? 'filter-selected' : ''"
              @click="selectedFilterCarrier(slotProps.option.text)"
            >
              {{ slotProps.option.text }}
            </span>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import FilterCarriers from "@/Extend/FilterCarriers";
import Dropdown from "primevue/dropdown";

export default {
  name: "CarrierSorter",
  mixins: [FilterCarriers],
  props: {
    carriers: Array
  },
  components: {
    Dropdown,
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/scss/components/FilterCarriers.scss";

.carriers-list {
  &__sortby {
    @include font-small-button-text;
    font-weight: 600;
    display: flex;
    justify-content: right;
    margin-top: 20px;
  }
  &__sortby-title {
    margin-right: 10px;
    @media (max-width: 500px) {
      margin-right: 5px;
    }
  }
  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    &--option {
      margin-left: 5px;
      margin-bottom: 2px;
      border: none;
      font-family: $font-family-portal;
      font-weight: 600;
      @include font-small-button-text;
    }
    p {
      color: $color-primary-company;
      @include font-small-button-text;
      font-weight: bold;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 5%;
      &--option {
        border: none;
        font-weight: 600;
        @include font-small-button-text;
      }
      p {
        color: $color-primary-company;
        @include font-small-button-text;
        font-weight: bold;
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
}
@media (max-width: 500px) {
  ::v-deep .p-dropdown .p-dropdown-trigger {
    width: auto;
    margin-left: 3px;
  }
}
</style>
