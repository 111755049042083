<template>
  <div class="partial-card">
    <div class="image-container">
      <img :src="carrierImage.url" :alt="carrier.carrier" />
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">Service class</span>
        <p class="section-container__text">
          {{ carrier.serviceClass }}
        </p>
      </div>
      <div class="section-container__text-container">
        <span class="section-container__title">{{ $t("carriers.transitTime") }}</span>
        <p class="section-container__text">
          {{ setTransitTime }}
        </p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="text-container">
        <span class="section-container__title">{{ $t("carriers.truckType") }}</span>
        <p class="section-container__text">{{ carrier.carrier }}</p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title"
          >{{ $t("carriers.quoteExpiration") }}&nbsp;</span
        >
        <p class="section-container__text">{{ carrier.expiration | dateFormat }}</p>
      </div>
      <span class="section-container__title" v-if="carrier.alert">
        <i v-b-popover.hover="carrier.description" class="ion ion-warning-outline"></i>
        {{ $t("settings.truckManagement.serviceThreshold") }}
      </span>
    </div>
    <div class="section-container">
      <button
        class="quote-button quote-button--blue"
        :class="checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''"
        @click="selectedCarrier(false, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier) || loadingInsurance"
      >
        <div class="container-info" v-if="!loadingInsurance">
          <div class="container-info__label">
            {{ $t("carriers.book") }}
          </div>
          <div class="container-info__prices">
            <span>USD{{ carrier.price.toFixed(2) | priceToUsd }}</span>
          </div>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
      <button
        class="quote-button quote-button--green"
        :class="checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''"
        @click="selectedCarrier(true, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier) || loadingInsurance"
      >
        <div class="container-info" v-if="!loadingInsurance">
          <div class="container-info__label">
            <p>
              {{ $t("carriers.bookWith") }}
              {{ $t("carriers.insurance") }}
            </p>
          </div>
          <div class="container-info__prices">
            <span>
              USD{{ (carrier.price + aditionalInsurance).toFixed(2) | priceToUsd }}
            </span>
          </div>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
    </div>
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <QuoteAcceptanceModal
        :insurance="insurance"
        :aditionalInsurance="aditionalInsurance"
        :showPickupPrice="showPickupPrice"
        :carrier="carrier"
        @closeModal="closeModal"
        @optionSelected="optionSelected"
      />
    </b-modal>
  </div>
</template>

<script>
import OptionsCarriers from "../../../../../../../Extend/OptionsCarriers";

export default {
  name: "OptionsComponent",
  mixins: [OptionsCarriers],
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/components/Modal.scss";

.partial-card {
  @include font-small-standart-text;
  display: grid;
  grid-template-columns: 15% 19% 19% 19% 28%;
  width: 100%;
  border: 1px solid $color-grey-border;
  border-radius: 20px;
  background: $color-white;
  box-shadow: 0px 0px 6px #00000029;
  color: $color-primary-company;
  @media (min-width: 1230px) {
    grid-template-columns: 15% 19% 23% 19% 24%;
  }
}
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
  }
}
.section-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  &:nth-child(5) {
    display: flex;
    flex-direction: column;
  }
  &__text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &--performance {
      margin-top: 5px;
    }
  }
  &__title {
    padding-right: 5px;
    font-weight: bold;
    i {
      color: #ff9900;
      font-style: italic;
      font-size: 23px;
    }
  }
  &__text {
    margin: 0px;
    overflow-wrap: break-word;
    inline-size: 100%;
  }
}
.quote-button {
  width: 100%;
  max-width: 200px;
  margin: 4px auto;
  padding: 5px;
  height: 43px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
}

.disabled-button {
  background-color: $color-disabled;
}
.container-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: clamp(0.6rem, 2vw, 0.7rem);
  &__label {
    margin: auto;
    width: 50%;
    max-width: 95px;
    font-size: 0.68rem;
  }
  &__prices {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 95px;
    text-align: center;
    word-break: break-all;
    letter-spacing: 0.5px;
    span {
      font-size: 11px;
      margin: auto 0;
    }
    p {
      font-weight: bold;
    }
  }
}
.divider {
  position: absolute;
  right: 0;
  height: 90%;
  border-left: 1px solid #dedede;
}
</style>
