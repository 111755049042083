<template>
  <div ref="loadContainer" class="load-container">
    <div class="load-container__content">
      <div class="load-container__load-name" v-if="chosenLoad">
        <span> {{ $t("carriers.quoteNumber") }} {{ chosenLoad.name }} </span>
      </div>
      <b-tabs card no-fade nav-wrapper-class="sticky-top">
        <div class="load-container__static-border-top"></div>
        <b-tab :title="modeName">
          <b-overlay :show="loading" rounded="sm">
            <LoadResume
              v-if="chosenLoad"
              :chosenLoad="chosenLoad"
              :quoteNumber="quoteNumber"
              :disableButtons="disableButtons"
              :areRequestInProcess="areRequestInProcess"
              :areMarkupsInRequest="areMarkupsInRequest"
              @handlingAreMarkupsInRequest="handlingAreMarkupsInRequest"
              @handlingAreRequestInProcess="handlingAreRequestInProcess"
              @applyMarkupsPrice="applyMarkupsPrice"
            />
            <LoadResumeSkeleton v-else />
            <CarriersList
              v-model="disableButtons"
              v-if="chosenLoad"
              :chosenLoad="chosenLoad"
              ref="carrier-list"
              @thereAreCarriersOrTrucks="thereAreCarriersOrTrucks"
            />
            <TrucksListSkeleton class="mt-4" v-else />
            <template #overlay>
              <div class="loading" v-show="loading">
                <b-icon
                  icon="truck"
                  font-scale="3"
                  animation="cylon"
                  class="loading__text"
                ></b-icon>
                <p class="loading__text">{{ $t("loading") }}...</p>
              </div>
            </template>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
    <div class="book-container__content"></div>
  </div>
</template>

<script>
import onReSize from "@/Extend/onResize";
import LoadResume from "./loadResume/LoadResume.vue";
import LoadResumeSkeleton from "./loadResume/LoadResumeSkeleton.vue";
import CarriersList from "./carriersList/CarriersList.vue";
import TrucksListSkeleton from "./carriersList/components/TrucksListSkeleton.vue";
import modes from "../modes";

export default {
  name: "CarriersInfo",
  components: {
    LoadResume,
    LoadResumeSkeleton,
    CarriersList,
    TrucksListSkeleton,
  },
  mixins: [onReSize],
  data() {
    return {
      loading: true,
      chosenLoad: null,
      screenWidth: null,
      modeName: null,
      carriers: null,
      trucks: null,
      quoteNumber: null,
      requote: false,
      disableButtons: true,
      areRequestInProcess: false,
      areMarkupsInRequest: false,
      loadContainerRef: null,
    };
  },
  async created() {
    this.loading = true;
    const { loadId } = this.$route.params;
    // if (loadId) {
    //   disableNavigatorBackButton();
    // }
    this.chosenLoad = await this.$store.getters["load/getChosenLoad"];
    this.quoteNumber = false;
    if (!this.chosenLoad) {
      this.chosenLoad = await this.$store.dispatch("load/getLoadById", loadId);
      this.quoteNumber = true;
    }
    this.modeSelected();
    this.loading = false;
  },
  mounted() {
    this.loadContainerRef = this.$refs.loadContainer;
  },
  methods: {
    async modeSelected() {
      // eslint-disable-next-line operator-linebreak
      const isMultiservice =
        "multiservice" in this.chosenLoad
          ? this.chosenLoad.multiservice
          : this.chosenLoad.load.multiservice;
      if (isMultiservice) {
        this.modeName = "ALL OPTIONS";
        return;
      }
      const modesKeys = Object.keys(modes).reverse();
      this.modeName =
        modes[modesKeys.find((key) => modes[key].modeId === this.chosenLoad.mode_id)]?.title;
      if (!this.modeName) {
        this.modeName = this.chosenLoad.mode_name.toUpperCase();
        this.loadContainerRef.classList.add("disable-pointer-events");
      }
    },
    thereAreCarriersOrTrucks(data) {
      this.areCarriersOrTrucks = data;
    },
    handlingAreMarkupsInRequest(data) {
      this.areMarkupsInRequest = data;
    },
    handlingAreRequestInProcess(data) {
      this.areRequestInProcess = data;
    },
    applyMarkupsPrice() {
      this.$refs["carrier-list"].updateCarriersPrice();
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-pointer-events {
  pointer-events: none;
}

::v-deep .modal-content {
  width: 90%;
}

.load-container {
  padding-top: 1.5rem;
  width: 97%;
  height: 100%;
  margin: 0px auto;
  font-family: $font-family-portal;
  &__content {
    padding-bottom: 20px;
    position: relative;
    width: 100%;
  }
  &__load-name {
    color: $color-primary-company;
    font-weight: bold;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    z-index: 4;
    span {
      position: absolute;
      right: 20px;
      top: 30px;
      @media (max-width: 450px) {
        right: 0px;
        top: 15px;
        overflow-wrap: break-word;
        width: 150px;
        text-align: right;
      }
    }
  }
  &__static-border-top {
    height: 5px;
    width: 100%;
    border: 1px solid $color-border-container;
    border-top-right-radius: 10px;
    border-bottom: none;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 61px;
    z-index: 2;
    background-color: $color-gray-light;
  }
}
.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

///****Tabs styles****\\\\
::v-deep .card-header {
  background-color: $color-white;
  border-bottom: 0px;
  padding: 12px 9.8px;
  z-index: 3;
}
::v-deep .card-body {
  background: $color-gray-light;
  border: 1px solid $color-border-container;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  font-family: $font-family-portal;
  padding: 2%;
  @media (max-width: 800px) {
    padding: 5%;
  }
}
::v-deep .nav-tabs .nav-link {
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  border-right: 1px solid $color-border-container;
  border-top: 1px solid $color-border-container;
  border-bottom: 1px solid $color-border-container;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 30px;
  cursor: default;
}
::v-deep .nav-tabs .nav-link.active {
  color: $color-primary-company;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  background-color: $color-gray-light;
  border: 1px solid $color-border-container;
  border-bottom: 1px solid $color-gray-light;
  padding: 15px 30px;
}
::v-deep .nav-tabs > li {
  &:first-child > a {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
// Delete the default buttons inside the number input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep {
  .bg-light {
    background-color: $color-gray-light !important;
  }
}
</style>
