function updateAditionalInsurance(newValue) {
  let aditionalInsurance;
  if (newValue > 25000) {
    aditionalInsurance = newValue * 0.0017;
  } else {
    aditionalInsurance = 35;
  }
  return aditionalInsurance;
}

export default updateAditionalInsurance;
