<template>
  <div class="trucks-list-skeleton">
    <div class="skeleton-carrier">
      <Skeleton class="skeleton-carrier__item mt-2" width="100%" height="100px"></Skeleton>
      <Skeleton class="skeleton-carrier__item mt-2" width="100%" height="100px"></Skeleton>
      <Skeleton class="skeleton-carrier__item mt-2" width="100%" height="100px"></Skeleton>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "TrucksListSkeleton",
  components: {
    Skeleton,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
