<template>
  <div class="load-resume-skeleton">
    <div v-if="screenWidth > 1200" class="load-resume-skeleton__desktop">
      <div class="field col-4 md:col-6">
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
      </div>
      <div class="field col-4 md:col-6">
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
      </div>
      <div class="field col-4 md:col-6">
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton class="mb-2"></Skeleton>
      </div>
    </div>
    <div v-if="screenWidth > 800 && screenWidth < 1200">
      <div class="load-resume-skeleton__desktop">
        <div class="field col-6 md:col-6">
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
        </div>
        <div class="field col-6 md:col-6">
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
          <Skeleton class="mb-2 mt-3"></Skeleton>
        </div>
      </div>
      <div class="field col-6 md:col-6 mt-4">
        <Skeleton class="mb-2 mt-3" width="50%" height="25px"></Skeleton>
        <Skeleton class="mb-2 mt-2" height="25px"></Skeleton>
        <Skeleton class="mb-2 mt-2"></Skeleton>
      </div>
    </div>
    <div v-if="screenWidth <= 800">
      <div class="field md:col-6">
        <Skeleton class="mb-2" width="50%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="47%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="53%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="47%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="47%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="50%"></Skeleton>
        <Skeleton class="mb-2 mt-3" width="50%"></Skeleton>
        <Skeleton class="mb-2 mt-4" height="30px"></Skeleton>
        <Skeleton class="mb-2 mt-3" height="30px"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import onReSize from "../../../../Extend/onResize";

export default {
  name: "LoadResumeSkeleton",
  components: {
    Skeleton,
  },
  mixins: [onReSize],
  data() {
    return {
      screenWidth: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.load-resume-skeleton {
  &__desktop {
    display: flex;
  }
}

::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
