<template>
  <div class="carriers-modal-list">
    <el-table :data="tableData" height="235">
      <el-table-column prop="carrier" label="Carrier">
        <template slot-scope="scope">
          {{ scope.row.carrier }}
        </template>
      </el-table-column>
      <el-table-column prop="priceDisplay" label="Price" width="70">
        <template slot-scope="scope">
          {{ scope.row.priceDisplay }}
        </template>
      </el-table-column>
      <el-table-column prop="sellDisplay" label="Sell" width="70">
        <template slot-scope="scope">
          {{ scope.row.sellDisplay }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="30">
        <template slot="header" slot-scope="scope">
          <b-form-checkbox
            class="document-item__checkbox"
            @change="chooseAllCarriers($event, scope)"
          />
        </template>
        <template slot-scope="scope">
          <b-form-checkbox v-model="scope.row.checked" class="document-item__checkbox" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters } from "vuex";
import FormatEasyPostPrices from "../../../../../Extend/FormatEasyPostPrices";

export default {
  name: "CarriersModalList",
  mixins: [FormatEasyPostPrices],
  props: {
    carriersList: Array,
  },
  data() {
    return {
      tableData: [],
      columnDefs: [
        {
          label: "Carrier",
          prop: "carrier",
          // width: "160",
        },
        {
          label: "Buy",
          prop: "priceDisplay",
          width: "70",
        },
        {
          label: "Sell",
          prop: "sellDisplay",
          width: "70",
        },
      ],
    };
  },
  watch: {
    carriersList: {
      deep: true,
      immediate: true,
      handler() {
        const tableData = this.carriersList ? this.$deepCopy(this.carriersList) : [];
        tableData.sort((a, b) => a.price - b.price);
        this.tableData = tableData.map((carrier) => {
          carrier.priceDisplay = `$${carrier.price.toFixed(1)}`;
          carrier.sell = carrier.price;
          carrier.sellDisplay = `$${carrier.price.toFixed(1)}`;
          carrier.checked = false;
          return carrier;
        });
      },
    },
    markups: {
      deep: true,
      handler(value) {
        const carriersList = this.$deepCopy(this.carriersList);
        const carriersWithMarkups = carriersList.map((carrier) => {
          let markupValue = (carrier.price * value.markup_percent) / 100;
          if (markupValue < value.markup_min) {
            markupValue = value.markup_min;
          }
          const price = carrier.price + markupValue;
          carrier.priceDisplay = `$${carrier.price.toFixed(1)}`;
          carrier.sell = parseFloat(price.toFixed(1));
          carrier.sellDisplay = `$${carrier.sell.toFixed(1)}`;
          return carrier;
        });
        this.tableData = this.$deepCopy(carriersWithMarkups);
      },
    },
  },
  computed: {
    ...mapGetters({ markups: "quoteFile/getMarkups" }),
  },
  methods: {
    getCarriersList() {
      const carriersList = this.tableData
        .filter((carrier) => !carrier.checked)
        .map((item) => item._id);
      return carriersList;
    },
    chooseAllCarriers(value) {
      this.tableData.forEach((carrier) => {
        carrier.checked = value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";
::v-deep {
  .el-table {
    font-size: 14px;
    background-color: #efefef;
    &__body-wrapper {
      background-color: #efefef;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: #f8f8f8;
        border-radius: 20px;
      }
    }
    &__header-wrapper {
      background-color: #efefef;
      tr,
      th {
        background: none;
        font-weight: 800 !important;
      }
      th {
        color: $color-primary-company;
        padding: 0px;
        .cell {
          // padding: px 0px;
          padding: 8px 0px;
        }
      }
    }
    &__row {
      .el-table__cell {
        font-weight: 600 !important;
        padding: 8px 0px;
        background-color: #efefef;
        color: $color-primary-company;
        .cell {
          padding: 0px;
        }
      }
      &:hover td {
        background: none;
      }
    }
  }
}
::v-deep {
  .document-item__checkbox {
    margin-right: 5px;
  }
}
</style>
