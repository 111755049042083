<template>
  <div class="carrier-message">
    <h1>
      Are you looking for something else?
      <a href="mailto:ltl@goglt.com" target="_blank">Contact us now!</a>
    </h1>
  </div>
</template>

<script>
export default {
  name: "CarrierMessage",
};
</script>

<style lang="scss" scoped>
.carrier-message {
  margin: 20px auto;
  text-align: center;
  padding-top: 45px;
  color: $color-primary-company;
  h1 {
    font-size: 20px;
    font-family: $font-family-portal;
  }
  a {
    color: $color-primary-company;
    text-decoration: underline;
  }
}
</style>
