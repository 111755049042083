<template>
  <div class="trucks-list">
    <template v-if="filterByGuaranteed && !filteredTrucks.length"> </template>
    <template v-else>
      <CarriersSorter :carriers="trucks" @getFilteredCarriers="getFilteredTrucks"></CarriersSorter>
      <span class="divider"></span>
      <div class="trucks-list__content">
        <div class="trucks-list__filter">
          <p class="trucs-list__type">{{ $t("trucks.truckOptions") }}</p>
        </div>
        <div class="trucks-container" id="trucks-container" v-if="screenWidth > 800">
          <OptionsTrucks
            v-for="(truck, index) in filteredTrucks"
            :key="index"
            :truck="truck"
            :truckImage="truck.logo"
            :cargoValue="chosenLoadCargoValue"
            :aditionalInsurance="aditionalInsurance"
            :loadingInsurance="loadingInsurance"
            @optionSelected="optionSelected"
            class="mb-3"
          >
          </OptionsTrucks>
          <template v-if="trucks.length === 0 && !allNotificationsObtained">
            <p>We are working to obtain trucks. This may take some time.</p>
            <b-spinner small label="Spinning"></b-spinner>
          </template>
          <div class="not-trucks">
            <template v-if="trucks.length === 0 && allNotificationsObtained">
              <p v-if="this.isBranded">
                Sorry, this time we couldn't find a truck for your request. Please confirm the
                information entered is correct or contact our Operations Team via email for a more
                specific quote.
              </p>
              <p v-else>
                Sorry, this time we couldn't find a truck for your request. Please confirm the
                information entered is correct or contact our Operations Team via email
                (ftl@goglt.com) for a more specific quote.
              </p>
            </template>
          </div>
        </div>
        <div class="trucks-container" id="trucks-container" v-if="screenWidth <= 800">
          <OptionsTruckResponsive
            v-for="(truck, index) in filteredTrucks"
            :key="index"
            :truck="truck"
            :cargoValue="chosenLoadCargoValue"
            :truckImage="truck.logo"
            :aditionalInsurance="aditionalInsurance"
            :loadingInsurance="loadingInsurance"
            @optionSelected="optionSelected"
            class="mb-3"
          ></OptionsTruckResponsive>
          <template v-if="trucks.length === 0 && !allNotificationsObtained">
            <p>We are working to obtain trucks. This may take some time.</p>
            <b-spinner small label="Spinning"></b-spinner>
          </template>
          <div class="not-trucks">
            <template v-if="trucks.length === 0 && allNotificationsObtained">
              <p v-if="this.isBranded">
                Sorry, this time we couldn't find a truck for your request. Please confirm the
                information entered is correct or contact our Operations Team via email for a more
                specific quote.
              </p>
              <p v-else>
                Sorry, this time we couldn't find a truck for your request. Please confirm the
                information entered is correct or contact our Operations Team via email
                (ftl@goglt.com) for a more specific quote.
              </p>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilterCarriers from "@/Extend/FilterCarriers";
import onReSize from "@/Extend/onResize";
import CarriersList from "../../../../../../../Extend/CarriersList";
import OptionsTruckResponsive from "./OptionsTruckResponsive.vue";
import OptionsTrucks from "./OptionsTrucks.vue";
import CarriersSorter from "../../CarriersSorter.vue";

export default {
  name: "TrucksList",
  mixins: [onReSize, CarriersList, FilterCarriers],
  props: {
    trucks: Array,
    carriers: Array,
    allNotificationsObtained: Boolean,
    filterByGuaranteed: Boolean,
  },
  components: {
    OptionsTrucks,
    OptionsTruckResponsive,
    CarriersSorter,
  },
  data() {
    return {
      aditionalInsurance: 35,
      truckSelected: null,
      chosenLoad: null,
      loadingInsurance: false,
      trucksList: [],
      filteredTrucks: [],
      isBranded: false,
    };
  },
  created() {
    this.addNewCarrier();
    const cargoValue = this.$store.getters["carriers/getChosenLoadCargoValue"];
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
    this.updateAditionalInsurance(cargoValue);
    // if (this.filterByGuaranteed) {
    //   this.filteredTrucks = this.filterCarriersByGuaranteed(this.trucks);
    // }
  },
  computed: {
    ...mapGetters({
      chosenLoadCargoValue: "carriers/getChosenLoadCargoValue",
      carriersImages: "carriers/getCarriersImages",
    }),
  },
  watch: {
    chosenLoadCargoValue() {
      this.updateAditionalInsurance(this.chosenLoadCargoValue);
    },
    trucks: {
      handler(value) {
        this.addNewCarrier();
        this.filteredTrucks = [...value];
      },
      immediate: true,
    },
  },
  methods: {
    addNewCarrier() {
      const filteredCarrierExpressFtl = this.carriers.filter(
        (carrier) => carrier.carrier === "305 EXPRESS USA (FTL)"
      );
      if (filteredCarrierExpressFtl.length > 0) {
        this.filteredTrucks.push(filteredCarrierExpressFtl[0]);
        this.getFilteredCarriers();
      }
    },
    getFilteredCarriers() {
      this.filterByPrice(this.filteredTrucks);
    },
    updateAditionalInsurance(newValue) {
      if (newValue > 25000) {
        this.aditionalInsurance = newValue * 0.0017;
      } else {
        this.aditionalInsurance = 35;
      }
    },
    async optionSelected(params) {
      this.loadingInsurance = true;
      this.truckSelected = params.truckName;
      this.chosenLoad.insurance_required = params.isInsurance;
      this.$store.commit("load/setChosenLoad", this.chosenLoad);
      this.changeView("StopsInfo", params.loyaltyInsurance, params.pickup, params.isInsurance);
      this.loadingInsurance = false;
    },
    getFilteredTrucks(trucks) {
      this.filteredTrucks = [...trucks];
      this.addNewCarrier();
      if (this.filterByGuaranteed) {
        this.filteredTrucks = this.filterCarriersByGuaranteed(this.trucks);
      }
    },
    changeView(routeName, loyaltyInsurance, pickup, insurance) {
      this.$router.push({
        name: routeName,
        params: {
          loadId: this.$route.params.loadId,
          // eslint-disable-next-line no-underscore-dangle
          carrierId: this.truckSelected._id,
          insurance,
        },
        query: {
          pickup,
          insurance,
          ...(loyaltyInsurance === true && { loyaltyInsurance: true })
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/components/FilterCarriers.scss";
.trucks-list {
  &__content {
    width: 98%;
    margin: 20px auto;
  }
  &__type {
    margin: 0px;
  }
  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    &--option {
      margin-left: 5px;
      margin-bottom: 2px;
      border: none;
      font-family: $font-family-portal;
      font-weight: 600;
      @include font-small-button-text;
    }
    p {
      color: $color-primary-company;
      @include font-standart-text;
      font-weight: bold;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5%;
      &--option {
        border: none;
        font-weight: 600;
        @include font-small-button-text;
      }
      p {
        color: $color-primary-company;
        font-weight: bold;
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
}
.divider {
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $color-border-container;
  @media (max-width: 800px) {
    margin: 3% 0 4% 0;
  }
}
.trucks-container {
  @extend %scroll-bar-styles;
  overflow: auto;
  padding-right: 10px;
  @media (max-width: 800px) {
    max-height: none !important;
  }
}
</style>
