<template>
  <div class="buy-rate">
    <label class="buy-rate__title">Include Buy Rate</label
    ><b-form-checkbox
      class="document-item__checkbox"
      v-model="includeBuyRate"
    />
  </div>
</template>

<script>
export default {
  name: "BuyRate",
  data() {
    return {
      includeBuyRate: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.buy-rate {
    display: flex;
    align-items: center;
    gap: 20px;
    &__title {
      margin: 0px;
      color: $color-primary-company;
      font-weight: 600;
    }
}
</style>
